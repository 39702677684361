export const SET_AUTH_DATA = "setauthdata";

export const SET_PRODUCT_DATA = "setproduct";

export const SET_CART_DATA = "setcartdata";
export const SET_WISHLIST = "setwishlist";

export const SET_SEARCH_RESULT = "setserachresult";
export const SET_SEARCH_LOADING = "setsearchloading";
export const SET_SEARCH_FILTER = "setsearchfilter";
export const SET_SEARCH_MODE = "setsearchmode";
export const SET_SEARCH_KEYWORD = "setsearchkeyword";
//MAINPAGE,
export const SET_INDEX_DATA = "setindexdata";
export const SET_ZIP_DATA = "setzipdata";

//Payment

export const SET_PAYMENT_DATA = "sethomedata";
export const SET_ADDRESS = "setaddress";
export const SET_STAGE = "setstage";

export const FETCHING_STAGE = "fetchingstage";
export const ADDRESS_STAGE = "addressstate";
export const PAYMENT_STAGE = "paymentstate";
export const LOADING_STAGE = "loadingstate";
export const SUCCESS_STAGE = "successstage";
export const FAILURE_STAGE = "failurestage";

//Other
// export const SET_WISHLIST_DATA=''
